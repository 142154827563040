<template>
  <div class="box">
    <div class="secondaryNavigation">
      <secondaryNavigation :list="list"></secondaryNavigation>
    </div>
    <div class="boxContent">
      <router-view/>
    </div>
  </div>
</template>
<script>
  import secondaryNavigation from '@/components/secondaryNavigation.vue';

  export default {
    name: 'index',
    components: {
      secondaryNavigation
    },
    data() {
      return {
        list: [
          {
            url: '/propur/prolist',
            name: '产品购买管理'
          }
        ]
      };
    }
  };
</script>
<style lang="scss" scoped>
  .boxContent {
    margin-top: 30px;
  }
</style>
